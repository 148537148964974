import { userCoords } from './userCoords.js';
import {
    fetchNearbyArtworks,
    fetchHighlightedArtworks,
    fetchRandomArtworks,
    getArtistArtworks,
    getCountryArtworks,
    getGalleryArtworks
} from './artworks.js';
import { privacyPolicyModal, helpModal } from './modals.js';
import { fromLonLat, toLonLat } from 'ol/proj';
import { vectorSource, callGeoLocation } from './map.js'; // Import vectorSource to add pins
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Style, Icon } from 'ol/style';

export async function initializeEventListeners(map) {

    fetchArtists();
    fetchCountries();
    fetchGalleries();

    const menu = document.getElementById('menu');
    const menuButton = document.getElementById('menu-open');
    const radiusSelect = document.querySelector('.radius-select');
    const highlightsBtn = document.querySelector('.highlights-btn');
    const randomBtn = document.querySelector('.random-btn');
    const resetBtn = document.querySelector('.reset-btn');
    const pageDivOne = document.querySelector('.page-div-1');
    const pageDivTwo = document.querySelector('.page-div-2');
    const menuArtistSelect = document.querySelector('.menu-artist-select');
    const menuCountrySelect = document.querySelector('.menu-country-select');
    const menuGallerySelect = document.querySelector('.menu-gallery-select');
    const artistSelect = document.querySelector('.artist-select');
    const gallerySelect = document.querySelector('.gallery-select');
    const artCardContainer = document.querySelector('.large-card-container');
    const noArtworksMessage = document.querySelector('.no-artworks-message');
    const privacyPolicyLink = document.querySelector('.menu-info-privacy-policy');
    const helpLink = document.querySelector('.menu-info-help');
    
    privacyPolicyLink.addEventListener('click', privacyPolicyModal);
    helpLink.addEventListener('click', helpModal);
    menuButton.addEventListener('click', toggleMenu);
    highlightsBtn.addEventListener('click', artworkHighlights);
    randomBtn.addEventListener('click', artworkRandom);

    function resetMenu(exclude = []) {
        
        if (!exclude.includes('country')) {
            menuCountrySelect.selectedIndex = 0;
        }
        if (!exclude.includes('artist')) {
            menuArtistSelect.selectedIndex = 0;
        }
        if (!exclude.includes('radius')) {
            radiusSelect.selectedIndex = 0;
        }
        if (!exclude.includes('gallery')) {
            menuGallerySelect.selectedIndex = 0;
        }

        toggleMenu();
        vectorSource.clear();

    }
    
    resetBtn.addEventListener('click', () => {
        callGeoLocation(map);
        resetMenu();
    });


    radiusSelect.addEventListener('change', updateRadius);

    // custom events from art-card
    document.addEventListener('artwork-click', zoomToLocation);
    document.addEventListener('gallery-click', zoomToLocation);


    document.querySelector('.open-div-1').addEventListener('click', function () {
        pageDivOne.classList.add('active');
    });

    document.querySelector('.close-div-1').addEventListener('click', function () {
        pageDivOne.classList.remove('active');
    });

    document.querySelector('.open-div-2').addEventListener('click', function () {
        pageDivTwo.classList.add('active');
    });

    document.querySelector('.close-div-2').addEventListener('click', function () {
        pageDivTwo.classList.remove('active');
    });

    // Event listeners for select elements in footer
    artistSelect.addEventListener('change', filterArtCards);
    gallerySelect.addEventListener('change', filterArtCards);

    // Event listeners for select elements in side menu
    menuArtistSelect.addEventListener('change', fetchArtistArtworks);
    menuCountrySelect.addEventListener('change', fetchCountryArtworks);
    menuGallerySelect.addEventListener('change', fetchGalleryArtworks);

    function fetchArtistArtworks() {
        const artistId = menuArtistSelect.value;
        if (!artistId) return;
        getArtistArtworks(map, artistId);
        resetMenu(['artist']);
    };

    function fetchCountryArtworks() {
        const country = menuCountrySelect.value;
        if (!country) return;
        getCountryArtworks(map, country);
        resetMenu(['country']);
    };

    function fetchGalleryArtworks() {        
        const gallery = menuGallerySelect.value;
        if (!gallery) return;
        getGalleryArtworks(map, gallery);
        resetMenu(['gallery']);
    };


    // Filter function to show or hide art-card components based on the selection
    function filterArtCards() {
        const selectedArtist = artistSelect.value;
        const selectedGallery = gallerySelect.value;

        let visibleCards = 0;

        // Get all art-card elements
        const artCards = artCardContainer.querySelectorAll('art-card');

        artCards.forEach(card => {
            const artistId = card.getAttribute('artist-id');
            const galleryId = card.getAttribute('gallery-id');

            // Show or hide the card based on artist and gallery selection
            const matchesArtist = !selectedArtist || artistId === selectedArtist;
            const matchesGallery = !selectedGallery || galleryId === selectedGallery;

            if (matchesArtist && matchesGallery) {
                card.style.display = ''; // Show the card
                visibleCards++;
            } else {
                card.style.display = 'none'; // Hide the card
            }
        });

        // Show "no artworks found" message if no cards are visible
        noArtworksMessage.style.display = visibleCards === 0 ? 'block' : 'none';
    }

    // Add a click event listener to the map
    map.on('click', updateMapOnClick);

    function toggleMenu() {
        const isMenuVisible = menu.style.right === '0px';
        if (isMenuVisible) {
            menu.style.right = '-320px';
            menuButton.innerHTML = '&#9776;';
        } else {
            menu.style.right = '0';
            menuButton.innerHTML = '&times;';
        }
    }


    function artworkHighlights() {
        fetchHighlightedArtworks(map);
        resetMenu();
    };

    function artworkRandom() {
        fetchRandomArtworks(map);
        resetMenu();
    };


    function zoomToLocation(event) {
        const { lat, lon } = event.detail;
        const coordinate = fromLonLat([lon, lat]);
        map.getView().animate({
            center: coordinate,
            duration: 1500,
            zoom: 16,
        });

        pageDivOne.classList.remove('active');
        pageDivTwo.classList.remove('active');

    }


    function updateMapOnClick(event) {
        // Get the clicked coordinates
        const coords = event.coordinate;
        const lonLat = toLonLat(coords);

        // Store the latitude and longitude in localStorage
        localStorage.setItem('latitude', lonLat[1]);
        localStorage.setItem('longitude', lonLat[0]);

        // Update localStorage userCoords
        userCoords.set({
            latitude: lonLat[1],
            longitude: lonLat[0]
        });

        vectorSource.clear();

        const pin = new Feature({
            geometry: new Point(coords),
        });

        pin.setStyle(new Style({
            image: new Icon({
                src: '/images/pin-icon.png',
                anchor: [0.5, 1],
                scale: .8,
            }),
        }));

        vectorSource.addFeature(pin);

        fetchNearbyArtworks(lonLat[1], lonLat[0], map);

        // set radius back to 100
        document.querySelector(".radius-select").value = "100";

    }

    function updateRadius() {
        const coords = userCoords.get();
        const selectElement = document.querySelector('.radius-select');
        const radius = parseInt(selectElement.value, 10);
        if (coords) {
            fetchNearbyArtworks(coords.latitude, coords.longitude, map, radius);
            resetMenu(['radius']);
        } else {
            console.error('User coordinates are not available.');
            resetMenu();
        }
    };

}


async function fetchAndPopulateSelect(url, selectClass, valueKey, textKey) {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        const selectElement = document.querySelector(selectClass);
        const fragment = document.createDocumentFragment();

        data.forEach(item => {
            const option = document.createElement('option');
            option.value = item[valueKey];
            option.textContent = item[textKey];
            fragment.appendChild(option);
        });

        selectElement.appendChild(fragment);
    } catch (error) {
        console.error(`There was an error fetching data from ${url}:`, error);
    }
}


export function fetchArtists() {
    return fetchAndPopulateSelect('/api/artists', '.menu-artist-select', 'artistId', 'artistTitle');
}


export function fetchCountries() {
    return fetchAndPopulateSelect('/api/countries', '.menu-country-select', 'countryId', 'countryTitle');
}


export function fetchGalleries() {
    return fetchAndPopulateSelect('/api/galleries', '.menu-gallery-select', 'galleryId', 'galleryTitle');
}


