import { userCoords } from './userCoords.js';
import { Style, Circle, Fill, Stroke, Icon } from 'ol/style';
import { Map as OlMap, View } from 'ol';
import { fromLonLat } from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fetchNearbyArtworks } from './artworks.js';
import { Spinner } from './spinner.js';

export const vectorSource = new VectorSource();

export const vectorLayer = new VectorLayer({
    source: vectorSource,
});

const markerStyle = new Style({
    image: new Circle({
        radius: 10,
        stroke: new Stroke({
            color: '#1119ba',
            width: 2,
        }),
    }),
});

export function initializeMap() {
    const map = new OlMap({
        target: 'map',
        layers: [
            new TileLayer({
                source: new OSM(),
            }),
            vectorLayer,
        ],
        view: new View({
            center: [0, 0],
            zoom: 2,
        }),
    });

    map.on('loadstart', Spinner.show());
    map.on('loadend', Spinner.hide());

    return map;
}

export function callGeoLocation(map) {
    Spinner.show();
    
    navigator.geolocation.getCurrentPosition(
        async (position) => {
            const coords = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            };

            // Store user's coordinates in localStorage
            userCoords.set(coords);
            
            const zoomLevel = 12;
            const olCoords = fromLonLat([coords.longitude, coords.latitude]);
            const view = map.getView();
            
            view.setZoom(zoomLevel);
            view.setCenter(olCoords);

            await fetchNearbyArtworks(coords.latitude, coords.longitude, map);
            // await fetchNearbyGalleries(coords.latitude, coords.longitude);

            Spinner.hide();
        },
        (error) => {
            console.log(error);
            Spinner.hide();
        }
    );
}


export async function updateMapWithArtworks(artworksMap, map) {
    // Keep the existing non-artwork features (e.g., the pin)
    const nonArtworkFeatures = vectorSource.getFeatures().filter(feature => !feature.get('isArtwork'));

    // Clear only the artwork features
    vectorSource.clear();

    // Re-add the non-artwork features (e.g., the pin)
    nonArtworkFeatures.forEach(feature => vectorSource.addFeature(feature));

    // Break the processing of artwork features into smaller chunks to avoid blocking the main thread
    const artworkEntries = Array.from(artworksMap.entries());
    const chunkSize = 100; // Adjust the chunk size as needed for performance

    for (let i = 0; i < artworkEntries.length; i += chunkSize) {
        const chunk = artworkEntries.slice(i, i + chunkSize);
        await processArtworkChunk(chunk);  // Process each chunk asynchronously
    }

    // Update the map extent
    const extent = vectorSource.getExtent();
    if (!isEmptyExtent(extent)) {
        const minZoom = 13; // Set a minimum zoom level
        map.getView().fit(extent, {
            padding: [50, 50, 50, 50],
            minResolution: map.getView().getResolutionForZoom(minZoom),
        });

        if (map.getView().getZoom() > minZoom) {
            map.getView().setZoom(minZoom);
        }
    }
}

// Helper function to process a chunk of artworks
async function processArtworkChunk(chunk) {
    const promises = chunk.map(([key, artwork]) => {
        return new Promise((resolve) => {
            const { galleryLon, galleryLat, title } = artwork;
            const marker = new Feature({
                geometry: new Point(fromLonLat([galleryLon, galleryLat])),
                name: title,
                isArtwork: true,  // Mark this feature as an artwork
            });
            marker.setStyle(markerStyle);
            vectorSource.addFeature(marker);
            resolve(); // Resolve the promise when the marker is added
        });
    });

    // Wait for all markers in the current chunk to be added
    await Promise.all(promises);
}

function isEmptyExtent(extent) {
    return extent[0] === Infinity || extent[1] === Infinity || extent[2] === -Infinity || extent[3] === -Infinity;
}
