import { updateMapWithArtworks } from './map.js';
import { Spinner } from './spinner.js';

class ArtworkManager {
    constructor(cardContainerSelector, miniCardContainerSelector, artistSelectSelector, gallerySelectSelector, messageElementSelector) {
        this.cardContainer = document.querySelector(cardContainerSelector);
        this.miniCardContainer = document.querySelector(miniCardContainerSelector);
        this.artistSelect = document.querySelector(artistSelectSelector);
        this.gallerySelect = document.querySelector(gallerySelectSelector);
        this.messageElement = document.querySelector(messageElementSelector);
    }

    clearMessage() {
        this.messageElement.style.display = 'none';
    }

    showMessage() {
        this.messageElement.style.display = 'block';
    }

    clearContainers() {
        this.cardContainer.innerHTML = '';
        this.miniCardContainer.innerHTML = '';
    }

    clearSelectOptions() {
        this.artistSelect.innerHTML = '<option value="">Select Artist</option>';
        this.gallerySelect.innerHTML = '<option value="">Select Gallery</option>';
    }

    updateSelectOptions(artistMap, galleryMap) {
        const artistSelectFragment = document.createDocumentFragment();
        artistMap.forEach((artistTitle, artistId) => {
            const option = document.createElement('option');
            option.value = artistId;
            option.textContent = artistTitle;
            artistSelectFragment.appendChild(option);
        });
        this.artistSelect.appendChild(artistSelectFragment);

        const gallerySelectFragment = document.createDocumentFragment();
        galleryMap.forEach((galleryTitle, galleryId) => {
            const option = document.createElement('option');
            option.value = galleryId;
            option.textContent = galleryTitle;
            gallerySelectFragment.appendChild(option);
        });
        this.gallerySelect.appendChild(gallerySelectFragment);
    }

    async updateArtworks(data, map) {
        this.clearMessage();
        this.clearSelectOptions();
        
        if (data.message) {
            this.clearContainers();
            this.showMessage();
            return;
        }

        const artworksMap = new Map(data.map(artwork => [artwork.artworkId, artwork]));
        this.clearContainers();

        const artistMap = new Map();
        const galleryMap = new Map();
        const cardFragment = document.createDocumentFragment();
        const miniCardFragment = document.createDocumentFragment();

        artworksMap.forEach(artwork => {
            const artCard = document.createElement('art-card');
            artCard.artworkData = artwork;
            cardFragment.appendChild(artCard);

            const miniArtCard = document.createElement('mini-art-card');
            miniArtCard.artworkData = artwork;
            miniCardFragment.appendChild(miniArtCard);

            if (artwork.artistId && artwork.artistTitle) {
                artistMap.set(artwork.artistId, artwork.artistTitle);
            }

            if (artwork.galleryId && artwork.galleryTitle) {
                galleryMap.set(artwork.galleryId, artwork.galleryTitle);
            }
        });

        this.miniCardContainer.appendChild(miniCardFragment);
        await updateMapWithArtworks(artworksMap, map);
        this.cardContainer.appendChild(cardFragment);
        this.updateSelectOptions(artistMap, galleryMap);
    }
}

class GalleryManager {
    constructor(cardContainerSelector, messageElementSelector) {
        this.cardContainer = document.querySelector(cardContainerSelector);
        this.messageElement = document.querySelector(messageElementSelector);
    }

    clearMessage() {
        this.messageElement.style.display = 'none';
    }

    showMessage() {
        this.messageElement.style.display = 'block';
    }

    clearCards() {
        this.cardContainer.querySelectorAll('gallery-card').forEach(card => card.remove());
    }

    updateGalleries(data) {
        this.clearMessage();
        if (data.message) {
            this.clearCards();
            this.showMessage();
            return;
        }

        const galleriesMap = new Map(data.map(gallery => [gallery.galleryId, gallery]));
        this.clearCards();

        const fragment = document.createDocumentFragment();
        galleriesMap.forEach(gallery => {
            const galleryCard = document.createElement('gallery-card');
            galleryCard.galleryData = gallery;
            fragment.appendChild(galleryCard);
        });

        this.cardContainer.appendChild(fragment);
    }
}

class Utils {
    static extractUniqueGalleries(artworkData) {
        return artworkData.reduce((acc, current) => {
            const galleryExists = acc.find(gallery => gallery.galleryId === current.galleryId);
            if (!galleryExists) {
                acc.push({
                    galleryId: current.galleryId,
                    galleryTitle: current.galleryTitle,
                    galleryAddress: current.galleryAddress,
                    galleryLat: current.galleryLat,
                    galleryLon: current.galleryLon,
                    galleryUrl: current.galleryUrl,
                });
            }
            return acc;
        }, []);
    }
}

// Reusable fetch function
async function fetchArtworks(url, map) {
    Spinner.show();
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();

        // store for artwork modal
        localStorage.setItem('artworks', JSON.stringify(data));

        const artworkManager = new ArtworkManager('.large-card-container-cards', '.mini-card-container', '.artist-select', '.gallery-select', '.no-artworks-message');
        const galleryManager = new GalleryManager('.large-gallery-container-cards', '.no-galleries-message');

        galleryManager.updateGalleries(data);
        await artworkManager.updateArtworks(data, map);

    } catch (error) {
        console.error(`There was an error fetching from ${url}:`, error);
    } finally {
        Spinner.hide();
    }
}

export async function fetchNearbyArtworks(lat, long, map, radius = 100) {
    const url = `/api/artworks_nearby/${lat}/${long}/${radius}`;
    await fetchArtworks(url, map);
}

export async function fetchHighlightedArtworks(map) {
    const url = `/api/artworks_highlights`;
    await fetchArtworks(url, map);
}

export async function fetchRandomArtworks(map) {
    const url = `/api/artworks_random`;
    await fetchArtworks(url, map);
}

export async function getArtistArtworks(map, artistid) {
    const url = `/api/artist_artworks/${artistid}`;
    await fetchArtworks(url, map);
}

export async function getCountryArtworks(map, countryId) {
    const url = `/api/country_artworks/${countryId}`;
    await fetchArtworks(url, map);
}

export async function getGalleryArtworks(map, galleryId) {
    const url = `/api/gallery_artworks/${galleryId}`;
    await fetchArtworks(url, map);
}

