// userCoords.js

const COORDS_KEY = 'userCoordinates';

export const userCoords = {

    set(coords) {
        if (coords && coords.latitude !== undefined && coords.longitude !== undefined) {
            localStorage.setItem(COORDS_KEY, JSON.stringify(coords));
        } else {
            console.error('Invalid coordinates:', coords);
        }
    },

    get() {
        const storedCoords = localStorage.getItem(COORDS_KEY);
        return storedCoords ? JSON.parse(storedCoords) : null;
    },

    update(newCoords) {
        const currentCoords = this.get();
        if (currentCoords) {
            const updatedCoords = { ...currentCoords, ...newCoords };
            this.set(updatedCoords);
        } else {
            this.set(newCoords);
        }
    },

    clear() {
        localStorage.removeItem(COORDS_KEY);
    }
};
