export const Spinner = {
    element: null,

    init(className) {
        this.element = document.querySelector(`.${className}`);
    },

    show() {
        if (this.element) {
            this.element.classList.add('spinner');
        } else {
            console.error('Spinner not initialized. Call Spinner.init(className) first.');
        }
    },

    hide() {
        if (this.element) {
            this.element.classList.remove('spinner');
        } else {
            console.error('Spinner not initialized. Call Spinner.init(className) first.');
        }
    }
};
