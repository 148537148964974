class ModalHandler {
    constructor(modalId, contentSelector, closeSelector) {
        this.modal = document.getElementById(modalId);
        this.modalContent = document.querySelector(contentSelector);
        this.closeButton = document.querySelector(closeSelector);
        
        this.closeButton.addEventListener('click', () => {
            this.modal.classList.remove('show');
        });
    }

    showPrivacyPolicy() {
        const template = `
            <h2>Privacy Policy</h2>
            <p>At Artsearcher, your privacy is a top priority. This policy explains how we handle your data and ensure your information remains safe.</p>
            <h3>Location Information</h3>
            <p>Our app may request access to your location to provide a better experience, such as showing nearby artworks. However, we do not store, share, or collect any location data. Your location is used solely to enhance your experience within the app while it is running.</p>
            <h3>Personal Data</h3>
            <p>We do not collect or store any personally identifiable information (PII) through the app. Your data remains on your device and is never shared with third parties.</p>
            <h3>Security</h3>
            <p>Artsearcher is designed with privacy in mind. Since we do not store or collect your personal information, there is no risk of data breaches involving your personal data.</p>
            <h3>Changes to this Policy</h3>
            <p>Any updates to this privacy policy will be reflected within the app. Please check back for any changes.</p>
        `;
        this.modalContent.innerHTML = template;
        this.modal.classList.add('show');
    }

    showHelp() {
        const template = `
            <h2>How to use Artsearcher</h2>
            <p>Artsearcher curates famous and notable artworks internationally and displays them by location, gallery, and artist.</p>
            <h3>Tips</h3>
            <p>Allow location permissions to see artworks nearby.</p>
            <p>Adjust the radius to see a larger area</p>
            <h3>Installation</h3>
            <p>Artsearcher is best installed as an app.</p>
            <p>On IOS, open the app in Safari and look for the 'share' button. Click on this and scroll down to the 'Add to Homescreen' option. Click this and add to home screen to install as a lightweight app.</p>
        `;
        this.modalContent.innerHTML = template;
        this.modal.classList.add('show');
    }
}

// Create an instance of ModalHandler
const modalHandler = new ModalHandler('artworkModal', '.modal-content', '.modal-close-button');

// Export functions for use in event.js
export function privacyPolicyModal(e) {
    e.preventDefault();
    modalHandler.showPrivacyPolicy();
}

export function helpModal(e) {
    e.preventDefault();
    modalHandler.showHelp();
}

// Listen for 'artwork-hold' events from card web components
document.addEventListener('artwork-hold', handleArtworkHold);

// Attach listeners to modal for swipe functionality
const modal = document.getElementById('artworkModal');
const modalContent = document.querySelector('.modal-content');
const closeButton = document.querySelector('.modal-close-button');
modal.addEventListener('touchstart', handleTouchStart, { passive: true });
modal.addEventListener('touchmove', handleTouchMove, { passive: true });
modal.addEventListener('touchend', handleTouchEnd, { passive: true });

closeButton.addEventListener('click', () => {
    modal.classList.remove('show');
    // prevent artifact
    setTimeout(() => {
        modalContent.classList.remove('slide-out-up', 'slide-out-down', 'slide-in-up', 'slide-in-down');
        modalContent.innerHTML = ''; // Clear modal content        
    }, 600); 
});


// Utility to retrieve stored artworks from localStorage
function getStoredArtworks() {
    return JSON.parse(localStorage.getItem('artworks')) || [];
}

let currentArtIndex = 0;

// Load artwork into modal with sliding animation
function loadArtIntoModal(index, direction = 'up') {
    const storedArtworks = getStoredArtworks();


    if (storedArtworks.length > 0) {
        const artwork = storedArtworks[index];
        
        const template = `
            <div class="modal-artwork-wrapper"> 
            <img id="artworkImage" src="${artwork.imageUrl}" alt="Artwork Image"/>
            <h2>${artwork.artworkTitle}</h2>
            <h4 style="margin-top:8px" class="modal-artist-title">${artwork.artistTitle}, ${artwork.artworkYear}</h4>
            <div class="modal-spacer"></div>
            <p>${artwork.artworkMedium}, <span>${artwork.artworkDimensions}</span></p>
            <p>${artwork.artworkStyle}</p>
            <div class="modal-spacer"></div>
            <h4>${artwork.galleryTitle}, ${artwork.countryTitle}</h4>
            </div>
            </div>
            <div class="modal-artist-content">
            <div class="modal-artist-content-inner">
            <span class="artist-content-close-button">Close</span>
            <img src="${artwork.artistImageUrl}" />
            <h3>${artwork.artistLastname}, ${artwork.artistFirstname}</h3>
            <h5>${artwork.artistNationality}, 
             ${artwork.artistBorn !== "0" ? `${artwork.artistBorn} - ` : ''}
             ${artwork.artistDied !== "0" ? `${artwork.artistDied}` : ''}
            </h5>
            <p>${artwork.artistShortBio !== "" ? `${artwork.artistShortBio}` : ''}</p>
            </div>`;

        // Clear any previous slide classes
        modalContent.classList.remove('slide-out-up', 'slide-out-down', 'slide-in-up', 'slide-in-down');

        // Apply the slide-out animation for the current content
        modalContent.classList.add(direction === 'up' ? 'slide-out-up' : 'slide-out-down');

        // After slide out completes, update content
        setTimeout(() => {
            modalContent.innerHTML = template;            
            // Apply the slide-in animation for new content
            modalContent.classList.add(direction === 'up' ? 'slide-in-up' : 'slide-in-down');
            
        }, 400); // Matches the CSS transition duration

        // show artist
        setTimeout(() => {
            const modalArtistTitle = modalContent.querySelector('.modal-artist-title');
            const modalArtistContent = modalContent.querySelector('.modal-artist-content');
            const modalArtworkWrapper = modalContent.querySelector('.modal-artwork-wrapper');
            const artistCloseButton = modalContent.querySelector('.artist-content-close-button');

            modalArtistTitle.addEventListener('click',(e) => {
                e.stopPropagation(); // Prevent bubbling up to parent elements
                modalArtistContent.classList.add('visible');
                modalArtworkWrapper.classList.add('blur');
            });
            
            modalContent.querySelector('.artist-content-close-button').addEventListener(
                'click', function(e) {
                    e.stopPropagation(); // Prevent bubbling up to parent elements
                    modalArtworkWrapper.classList.remove('blur');
                    modalArtistContent.classList.remove('visible');
                }
            );
        }, 1200); // make sure we have content in DOM
        
    } else {
        console.error("No artworks found in localStorage");
    }
}

// Handle swipe direction and load next or previous artwork
function handleSwipe(direction) {
    const storedArtworks = getStoredArtworks();
    const totalArtworks = storedArtworks.length;

    if (direction === 'up') {
        // Increment index or loop back to the first artwork if at the end
        currentArtIndex = (currentArtIndex + 1) % totalArtworks;
    } else if (direction === 'down') {
        // Decrement index or loop back to the last artwork if at the beginning
        currentArtIndex = (currentArtIndex - 1 + totalArtworks) % totalArtworks;
    }
    loadArtIntoModal(currentArtIndex, direction);
}

// Swipe handling
let touchStartY = 0;
let touchEndY = 0;
let touchMoved = false; // Flag to detect if touch actually moved

function handleTouchStart(e) {
    touchStartY = e.changedTouches[0].screenY;
    touchMoved = false; // Reset the flag on touch start
}

function handleTouchMove(e) {
    touchEndY = e.changedTouches[0].screenY;
    const swipeDistance = Math.abs(touchEndY - touchStartY);
    
    // If swipe distance exceeds a small threshold, mark as moved
    if (swipeDistance > 10) {
        touchMoved = true;
    }
}

function handleTouchEnd() {
    if (!touchMoved) {
        return; // If no significant move, don't handle swipe
    }
    
    const swipeDistance = touchEndY - touchStartY;
    const swipeThreshold = 220; // Adjust threshold as needed

    if (swipeDistance > swipeThreshold) {
        handleSwipe('down'); // Swipe down to previous
    } else if (swipeDistance < -swipeThreshold) {
        handleSwipe('up'); // Swipe up to next
    }
}

// Function to handle the 'artwork-hold' event
function handleArtworkHold(event) {
    const artworkId = event.detail.artworkId;
    const storedArtworks = getStoredArtworks();

    // Find the artwork by its ID
    const artworkIndex = storedArtworks.findIndex(artwork => artwork.artworkId === artworkId);
    
    if (artworkIndex !== -1) {
        // Set the current index to the artwork that was clicked
        currentArtIndex = artworkIndex;
        
        // Load the clicked artwork into the modal
        loadArtIntoModal(currentArtIndex);
        
        // Show the modal
        modal.classList.add('show');
    } else {
        console.error("Artwork not found in localStorage.");
    }
}

// Function to show or hide the artist content
function showArtistContent() {
    const artistContent = document.querySelector('.modal-artist-content');
    if (artistContent) {
        artistContent.classList.toggle('hidden');
    }
}
